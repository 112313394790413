import styled from 'styled-components'
import { colors, media } from 'themes'

export const Photo = styled.div`
  height: 480px;
  background: ${({ image }) => `url(${image})`} no-repeat center center;
  background-size: cover;
`

export const BottomWrapper = styled.div`
  padding: 96px 0 184px;

  ${media.md`
    padding-bottom: 64px;
  `}

  ${media.sm`
    padding: 80px 0 30px;
  `}
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 72px;
  margin-left: 40px;

  ${media.xl`
    margin-left: 180px;
  `}

  ${media.md`
    display: inline-flex;
    width: 230px;
    margin-left: 0;
    margin-top: 32px;
  `}

  ${media.sm`
    margin-bottom: 50px;
  `}
`

export const Icon = styled.img`
  margin-bottom: 24px;
  height: 22px;
`

export const KeyBox = styled.div`
  position: relative;
  height: 576px;
  background: ${colors.darkerBlue};
  margin-top: -100px;
  font-size: 48px;
  font-weight: 300;
  line-height: 58px;
  color: white;
  padding-top: 100px;
  padding-left: 40px;

  ${media.xl`
    width: 370px;
    margin-left: auto;
    height: 490px;
  `}

  ${media.md`
    height: 330px;
    margin-top: -280px;
    padding-top: 32px;
  `}

  ${media.sm`
    margin-top: 50px;
    margin-left: -15px;
    margin-right: -15px;
  `}

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 40px;
    width: 70px;
    height: 265px;
    background: linear-gradient(0deg, #1988C3 0%, #00A8E5 100%);

    ${media.md`
      height: 80px;
    `}

    ${media.xl`
      height: 180px;
    `}
  }
`
