import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import Text from 'components/shared/Text'
import SectionTitle from 'components/shared/SectionTitle'
import Container from 'components/shared/Container'

import FileAboutPhoto from 'images/aboutUs-photo.jpg'
import FileIconHandshake from 'images/icon-handshake.svg'
import FileIconPin from 'images/icon-pin.svg'
import FileIconShield from 'images/icon-shield.svg'

import { Photo, BottomWrapper, Info, Icon, KeyBox } from './styled'

const AboutUs = () => (
  <>
    <Container>
      <Row>
        <Col lg={12}>
          <SectionTitle main>About Us</SectionTitle>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4}>
          <Text main>
            Metro-Tech Erectors Corp. is a respected market leader in commercial
            and industrial window installation based in the New York Tri-State
            area.
          </Text>

          <Text>
            Founded in 2004 by foremen with over 20 years experience in the
            industry, our work encompasses wide range solutions, materials,
            techniques and finishes performed to industry standards. We work
            closely with high quality suppliers to ensure a safe, efficient, and
            seamless service.
          </Text>
        </Col>
        <Col md={6} lg={4}>
          <Text>
            We are fully committed to providing highly technical expertise,
            dedication to detail, and professionalism at every stage.
          </Text>
        </Col>
        <Col md={6} mdOffset={6} lg={4} lgOffset={0}>
          <KeyBox>
            Safety.
            <br />
            Precision.
            <br />
            Efficiency.
          </KeyBox>
        </Col>
      </Row>
    </Container>

    <Photo image={FileAboutPhoto} />

    <BottomWrapper>
      <Container>
        <Row>
          <Col md={6} lg={4}>
            <Text>
              Our skilled and dynamic work force comprises union workers from
              Iron Workers Local 580, Glaziers Local 1087, Operating Engineers
              Local 14 and PCC Caulkers Local 1.
            </Text>

            <Text>
              We are a NYC School Construction Authority (NYCSCA) and Dormitory
              Authority of the State of New York (DASNY) approved contractor,
              and take pride in offering the best services to ensure reliability
              and outstanding workmanship on projects that make a difference.
            </Text>
          </Col>
          <Col md={6} lg={4}>
            <Text>
              Metro-Tech Erectors Corp. offers a personal and dedicated service
              with an exceptional portfolio of work in window installation.
            </Text>

            <Text>
              We customize to our clients’ specific needs and continually strive
              to build strong relationships to achieve the best results.
            </Text>
          </Col>
          <Col md={12} lg={4}>
            <Info>
              <Icon src={FileIconShield} />
              Fully insured
            </Info>
            <Info>
              <Icon src={FileIconHandshake} />
              Founded in 2004
            </Info>
            <Info>
              <Icon src={FileIconPin} />
              Based in the New York Tri&#8209;State&nbsp;area
            </Info>
          </Col>
        </Row>
      </Container>
    </BottomWrapper>
  </>
)

export default AboutUs
