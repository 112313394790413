import React from 'react'
import Layout from 'components/shared/Layout'

import AboutUs from 'components/About/AboutUs';
import ContactLink from 'components/shared/ContactLink'

const About = () => (
  <Layout>
    <AboutUs />
    <ContactLink />
  </Layout>
)

export default About
